import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Grid, Typography } from '@material-ui/core';
import logoYatch from '../media/img/logoyatchparty.png';
import youre from '../media/img/youre.png';
import backChests from '../media/img/bloom-bg.jpg';

const Yatchparty = () => {
  const [wallet, setWallet] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [confirmationColor, setConfirmationcolor] = useState('green');
  const [isError, setIsError] = useState(false);
  const [stateSub,setState] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://api.bloomverse.io/clients/form', { email: wallet, origin:'yatchparty' });
      setConfirmationcolor("green");
      setConfirmation('Your sol wallet has been submitted successfully!');
      setState(true);
    } catch (error) {
        setState(false);
        setConfirmationcolor("red");
      console.error('Error submitting wallet:', error);
      setConfirmation('An error occurred. Please check your Solana Address and try again later.');
    }
  };

  const handleInputChange = (e) => {
    setWallet(e.target.value);
    // Reset error state when the user starts typing
    setIsError(false);
  };

  return (
    <Grid container className="content-grid-section-chests" style={{backgroundImage:`url(${backChests})`, backgroundSize:'cover', backgroundRepeat:'none'}}> 
      <Container maxWidth="xs">
        <Grid container spacing={0} justify="center" alignItems="top" style={{ height: '600px' }}>
          <Grid item xs={12} style={{textAlign:'center', marginTop:130}}>
            <img src={logoYatch} alt="Logo" style={{ width: 300, height: 300, textAlign:'center' }} />
          </Grid>
         {!stateSub ? (
             <Grid item xs={12}>
             <form onSubmit={handleSubmit}>
               <TextField
                 label="Sol Address"
                 variant="outlined"
                 fullWidth
                 value={wallet}
                 onChange={handleInputChange}
                 style={{textAlign:'center', backgroundColor:'#FFF',borderRadius:8}}
                 error={isError || (wallet.length > 0 && wallet.length > 100)} // Update error condition
                 helperText={isError ? 'Wallet address is required' : (wallet.length > 100 ? 'Wallet address is too long' : '')}
               />
               <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
                 Submit
               </Button>
             </form>
           </Grid>
         ): (
            <div><img src={youre} alt="you're in " style={{ width: 350, height: 300, textAlign:'center' }} /></div>
         )}
         
          {confirmation && (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" style={{color:confirmationColor}}>
                {confirmation}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Yatchparty;
