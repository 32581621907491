import React from 'react';
import Navbar from './components/navbar';
import Home from './components/home';
import Yatchparty from './components/yatchparty';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Routes with Navbar */}
          <Route path="/" element={<WithNavbar><Home route="home" /></WithNavbar>} />
          <Route path="/team" element={<WithNavbar><Home route="team" /></WithNavbar>} />
          <Route path="/team/:id" element={<WithNavbar><Home route="team-member" /></WithNavbar>} />
          <Route path="/partners" element={<WithNavbar><Home route="partners" /></WithNavbar>} />
          <Route path="/partners/:id" element={<WithNavbar><Home route="partner" /></WithNavbar>} />
          <Route path="/tournaments" element={<WithNavbar><Home route="tournaments" /></WithNavbar>} />
          <Route path="/tournaments/:id" element={<WithNavbar><Home route="tournament" /></WithNavbar>} />
          
          {/* Route for Yatchparty without Navbar */}
          <Route path="/yachtparty" element={<Yatchparty />} />
        </Routes>
      </div>
    </Router>
  );
}

// Higher-order component to wrap elements with Navbar
const WithNavbar = ({ children }) => (
  <>
    <Navbar />
    {children}
  </>
);

export default App;
