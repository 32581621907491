import { Hidden, Container } from '@material-ui/core';

import icon from '../media/img/logo.png'
import playimage from '../media/img/playbutton.png'

import { Link } from 'react-router-dom'
import backgroundVideo from '../media/vid/backgroundVideo2.webm'
import NavDrawer from '../components/navDrawer';

const Navbar = () => {

    const openUrlInTab = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div>
            <Hidden smDown>
                
                <nav className='navbar'>
                    <Container>
                    <div className='links'>
                        <Link to='/' className='navbar-item' style={{marginLeft: 0, marginTop:"-20px"}}>
                            <img alt='bloomverse icon' src={icon} style={{height: 60}}></img>
                        </Link>
                    
                    <div className='navbar-item-text' style={{cursor:'pointer'}} onClick={ () => openUrlInTab('https://bloomverses-organization.gitbook.io/bloomverse-whitepaper/')}>WHITEPAPER</div>
                        <Link to='/team' className='navbar-item-text'>TEAM</Link>
                      {/* <Link to='/partners' className='navbar-item'>PARTNERS</Link> */}
                        <Link to='/tournaments' className='navbar-item-text' style={{marginRight: 0}}>TOURNAMENTS</Link>
                        <div className='navbar-item' style={{marginLeft:0, marginTop:"-14px"}} onClick={ () => openUrlInTab('https://play.bloomverse.io')}> <img alt='play' src={playimage} style={{height: 70}}></img></div>
                    </div>
                    </Container>
                </nav>
            </Hidden>
            <Hidden mdUp>
                <NavDrawer/>
                <div className='background-video-container-small'>
                    
                </div>
                <div className='content-divider-small'/>
            </Hidden>
        </div>  
        
    );
}
 
export default Navbar;