import { IconButton, Box, ListItemButton } from '@mui/material'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BloomverseLogo from '../media/img/logo.png'
import ham from '../media/img/ham.png'

import { List, ListItemText } from '@material-ui/core';

const NavDrawer = () => {
    
    const openUrlInTab = (url) => {
        window.open(url, '_blank');
    }

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(0)
    
    return (
        <>
        <div className='navbar-drawer-header'>
            <img src={BloomverseLogo}  height={50} style={{textAlign:"center", marginLeft:'10px', marginTop:'-5px'}}/>
            <IconButton style={{alignItems:'flex-end'}} size='large' edge='end' >
                <img src={ham} style={{height:20, float: 'right',marginRight:'20px'}} onClick={() => setIsDrawerOpen(!isDrawerOpen)}/>
               
            </IconButton>
        </div>
        <div>
        {isDrawerOpen && 
            <Box style={{width:'100%', backgroundColor:'#2A393C'}}>
                <List>
                    <Link to={'/'}>
                        <ListItemButton selected={selectedDrawerIndex === 0} onClick={() => {setSelectedDrawerIndex(0)}}>
                            <ListItemText className='navbar-drawer-item' color='#fff' primary={'HOME'}/>
                        </ListItemButton>
                    </Link>
                        <ListItemButton selected={selectedDrawerIndex === 1} onClick={() => {setSelectedDrawerIndex(1); openUrlInTab('https://play.bloomverse.io')}}>
                            <ListItemText className='navbar-drawer-item' primary={'PLAY'}/>
                        </ListItemButton>
                        <ListItemButton selected={selectedDrawerIndex === 2} onClick={() => {setSelectedDrawerIndex(2); openUrlInTab('https://bloomverses-organization.gitbook.io/bloomverse-whitepaper/')}}>
                            <ListItemText className='navbar-drawer-item' primary={'WHITEPAPER'}/>
                        </ListItemButton>
                    <Link to={'/team'} style={{textDecoration:'none'}}>
                        <ListItemButton selected={selectedDrawerIndex === 3} onClick={() => {setSelectedDrawerIndex(3)}}>
                            <ListItemText className='navbar-drawer-item' primary={'TEAM'}/>
                        </ListItemButton>
                    </Link>
                    <Link to={'/partners'} style={{textDecoration:'none'}}>
                        <ListItemButton selected={selectedDrawerIndex === 4} onClick={() => {setSelectedDrawerIndex(4)}}>
                            <ListItemText className='navbar-drawer-item' primary={'PARTNERS'}/>
                        </ListItemButton>
                    </Link>
                    <Link to={'/tournaments'} style={{textDecoration:'none'}}>
                        <ListItemButton selected={selectedDrawerIndex === 5} onClick={() => {setSelectedDrawerIndex(5)}}>
                            <ListItemText className='navbar-drawer-item' primary={'TOURNAMENTS'}/>
                        </ListItemButton>
                    </Link>
                </List>
            </Box>}
        </div>
        </>

     );
}
 
export default NavDrawer;