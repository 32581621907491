import TournamentList from './tournamentList';
import TeamList from './teamList';
import PartnerList from './partnerList';
import Tournament from './tournament';
import Yatchparty from './yatchparty';
import { useParams } from 'react-router-dom';
import Welcome from './welcome';

const homeContentSwitch = (route, param) => {
  switch (route) {
    case 'home':
        return <Welcome/>
    case 'team':
        return <TeamList/>
    case 'team-member':
        return <TeamList data={param}/>
    case 'partners':
        return <PartnerList/>
    case 'tournaments':
        return <TournamentList/>
    case 'tournament':
        return <Tournament/>
 
    default:
      return ('');
  }
}

const Home = (props) => {

  const param = useParams()
  const route = props.route;

  return ( 
    <div className='content'>
      { homeContentSwitch(route, param) }
    </div>
  );
}
 
export default Home;