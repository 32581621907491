import { Grid, Hidden, Button, Modal, Backdrop, Container } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import welcomeImage from '../media/img/Website Main Page.png'
import HelpIcon from '@mui/icons-material/Help';
import AboutTournaments from '../media/docs/bloom-tournaments.pdf'
import AboutChests from '../media/docs/bloom-chests.pdf'
import React, { useRef,useState, useEffect, useContext, useLayoutEffect} from "react";
import ReactPlayer from 'react-player';
import video1 from '../media/vid/Gameplay footage 3.mp4';
import poster1 from '../media/img/site/bloomverseteaser.webp';
import bluebar from '../media/img/bluebar.png'
import backChests from '../media/img/backcuad.png'
import backSquare from '../media/img/backsquareblue.png'
import aboutTournamentsImg from '../media/img/abouttournaments.png'
import partnersImg from '../media/img/partnersword.png'
import partnersImg2 from '../media/img/partnerswordsmall.png'
import backgroundVideo from '../media/vid/backgroundVideo2.mp4'


const Welcome = () => {
  
  const ref = useRef(null);

  let [playerModal,setPlayerModal] = useState(false);
  let [playerVideo,setPlayerVideo] = useState("-Arp51Hk8X8");

  let anim1Ref = React.createRef();


  const openUrlInTab = (url) => {
    window.open(url, '_blank');
  }  
  const handleVideoModal = () =>{
    setPlayerModal(false);
  }


  const animtease = () => {
    //setAnim1(true);
     setPlayerModal(true);
     setPlayerVideo("usOEI1yMS10")
 }
  
  return (

        <div className='root-div'>
          

          <Modal  style={{display:'flex',alignItems:'center',justifyContent:'center', backgroundColor:"#000000eb !important", zIndex:11000}}
                    aria-labelledby="video"
                    aria-describedby="video"
                    className="MuiBackdrop"
                    open={playerModal}
                    onClose={handleVideoModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    
                    }}
                >
                    <Fade in={playerModal}>
                    
                    <div style={{width:'100%', backgroundColor:"#000", border:'0px solid'}}>
                          <div onClick={handleVideoModal} style={{margin:'10px auto 10px',borderRadius:40,width:'40px',height:'40px',textAlign:'center',fontSize:'24px', zIndex:1000, backgroundColor:'rgb(26 26 26)', cursor:'pointer', color:'rgb(86 86 86)', fontWeight:'bold'}}>x</div>
                        <ReactPlayer controls={true} width="100%" url={'https://www.youtube.com/watch?v=' + playerVideo} style={{zIndex:11001, width:'100%', minHeight:'80vh',margin:'0px auto 0px',textAlign:'center'}} />
                    </div>
                    </Fade>
          </Modal>

          <Hidden smDown>
          
          <Container maxWidth={'xl'}>
          <Grid container  style={{height:"700px"}}  > 
          <div className='background-video-container-big'>
                    <video className='background-video' autoPlay loop muted  playsInline="true" disablePictureInPicture="true">
                        <source src={backgroundVideo} type='video/mp4' />
                    </video>
                    <Grid container alignContent="flex-start" alignItems="flex-start">
              <Grid item xs={6}>
                    <img alt='play' src={aboutTournamentsImg} style={{position:'relative',cursor:'pointer',height:"100px", textAlign:"left",margin:"514px auto"}} onClick={() => openUrlInTab(AboutTournaments)} />
              </Grid>
             
              <Grid item style={{zIndex:1000}} xs={6}>
        
                      <video
                          poster={poster1}
                          onClick={animtease}
                          ref = { anim1Ref }      
                            loop
                            autoPlay
                            playsInline="true" disablePictureInPicture="true"
                            muted
                            style={{
                              backgroundColor: 'rgb(1 2 3 / 62%)',
                              cursor:'pointer',
                              position:'relative',
                              marginRight:'10%',
                              top: 400,
                              textAlign:'right',
                              width: '330px',
                              height: '190px',
                              borderRadius: '8px',  
                              zIndex : 10,
                             
                            }}  >  <source src= { video1 } type="video/mp4" />
                        </video>

                       
                </Grid>
               
             
            </Grid>       
          </div>
          </Grid>

          </Container>
           
          <div xs={12} >
          {/* <div className="video-bottom-bar">
                        </div>  */}
          <div style={{width:'100%',zIndex:100,height:'auto'}}>
             <img alt='play' src={bluebar} style={{cursor:'pointer',width: "100%", height:"36px",marginTop:10}} />
         </div>              
          </div>                  

          <Grid container  className="content-grid-section-chests" style={{backgroundImage:`url(${backChests})`, backgroundSize:'cover', backgroundRepeat:'none'}}> 


             

              <Grid item xs={12} style={{textAlign:"center"}} >
              
              
              <img alt='play' src={backSquare} style={{cursor:'pointer',height:"660px", textAlign:"center",margin:"100px auto"}} onClick={() => openUrlInTab(AboutChests)} />
              
              </Grid>

             {/* <Grid item xs={6} >
              
                          </Grid>  */}


          </Grid>


         



        

          <div className="content-grid-section-partners">
              <img alt='play' src={partnersImg} style={{width:'70%',padding:50,marginBottom:'100px', textAlign:"center",margin:"0px auto"}} />
          </div>

          </Hidden>

          {/*-----------------------------------------------------SMALL ----------------------------------------------------*/}
          <Hidden mdUp>

          <Grid item md={12}>

         
          <Grid container  style={{height:"300px"}}  > 
          <div className='background-video-container-small'>
                    <video className='background-video-small' autoPlay loop muted  playsInline="true" disablePictureInPicture="true">
                        <source src={backgroundVideo} type='video/mp4' />
                    </video>
                  
          </div>
          </Grid>

          <div xs={12} >
          {/* <div className="video-bottom-bar">
                        </div>  */}
          <div style={{width:'100%',zIndex:100,height:'auto'}}>
             <img alt='play' src={bluebar} style={{cursor:'pointer',width: "100%", height:"26px",marginTop:10}} />
         </div>              
          </div>      

          
           
                    

          <Grid container  className="content-grid-section-chests-small" style={{backgroundImage:`url(${backChests})`, backgroundSize:'cover', backgroundRepeat:'none'}}> 


          <Grid item xs={12} style={{textAlign:'center'}}>
              <img alt='play' src={aboutTournamentsImg} style={{position:'relative',cursor:'pointer',height:"100px", textAlign:"center",margin:"30px -20px",maxWidth:'100%'}} onClick={() => openUrlInTab(AboutTournaments)} />
          </Grid>
             
          <Grid alignContent="center" alignItems="center" item style={{zIndex:1000,textAlign:"center"}} xs={12}>
            <video
                      poster={poster1}
                      onClick={animtease}
                      ref = { anim1Ref }      
                        loop
                        autoPlay
                        muted
                        playsInline="true" disablePictureInPicture="true"
                        style={{
                          backgroundColor: 'rgb(1 2 3 / 62%)',
                          cursor:'pointer',
                          position:'relative',
                          marginRight:'0%',
                          top: 0,
                          textAlign:'center',
                          maxWidth: '100%',
                          padding:20,
                          height: '190px',
                          borderRadius: '8px',  
                          zIndex : 10,
                        }}  >  <source src= { video1 } type="video/mp4" />
              </video>    
          </Grid>

            <Grid item xs={12} style={{textAlign:"center"}} >
              <img alt='play' src={backSquare} style={{cursor:'pointer',maxWidth:'100%', textAlign:"center",margin:"100px auto"}} onClick={() => openUrlInTab(AboutChests)} />
            </Grid>


          </Grid>


         



        
          <Grid item xs={12}>
          <div className="content-grid-section-partners">
              <img alt='play' src={partnersImg2} style={{width:'90%',padding:20,marginBottom:'100px', textAlign:"center",margin:"0px auto"}} />
          </div>
          </Grid>
              </Grid>
          </Hidden>
        </div>
    );
}

export default Welcome;